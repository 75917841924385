<template>
	<v-menu v-model="menu" :close-on-content-click="false" full-width lazy min-width="290px" offset-y transition="scale-transition" @input="onMenuSwitchState">
		<template v-slot:activator="{ on }">
			<v-text-field v-model="model" :label="label" prepend-inner-icon="event" readonly v-on="on"></v-text-field>
		</template>
		<v-date-picker
			ref="picker"
			v-model="date"
			:picker-date="date"
			color="primary"
			:min="oldestDate"
			:max="youngestDate"
			no-title
			reactive
			@input="onInput"
		></v-date-picker>
	</v-menu>
</template>

<script>
export default {
	name: 'YearPicker',
	props: {
		label: {
			required: false,
			type: String,
			default: () => ''
		},
		value: {
			required: true,
			type: Number,
			default: () => new Date().getFullYear()
		}
	},
	data: function () {
		return {
			date: null,
			menu: false
		}
	},
	computed: {
		oldestDate: function () {
			const dateNow = new Date()
			if (this.value) {
				dateNow.setFullYear(this.value - 20)
			} else {
				dateNow.setFullYear(dateNow.getFullYear() - 20)
			}
			return dateNow.toISOString().substring(0, 10)
		},
		youngestDate: function () {
			const dateNow = new Date()
			if (this.value) {
				dateNow.setFullYear(this.value + 20)
			} else {
				dateNow.setFullYear(dateNow.getFullYear() + 20)
			}
			return dateNow.toISOString().substring(0, 10)
		},
		model: {
			get: function () {
				return this.value
			},
			set: function (newValue) {
				this.$emit('input', newValue)
			}
		}
	},
	watch: {
		value: {
			handler: function (newVal, oldVal) {
				if (!newVal) {
					const currentDate = new Date()
					this.date = currentDate.toISOString().substring(0, 10)
					this.onInput()
				} else if (!this.date || newVal !== oldVal) {
					const date = new Date()
					date.setFullYear(newVal)
					this.date = date.toISOString().substring(0, 10)
				}
			},
			immediate: true
		}
	},
	methods: {
		onInput: function () {
			const date = new Date(this.date)
			const year = date.getFullYear()
			this.setValue(year)
			this.menu = false
		},
		onMenuSwitchState: function () {
			if (this.menu) {
				this.$nextTick(() => {
					this.$refs.picker.activePicker = 'YEAR'
					setTimeout(() => {
						this.scrollToCurrentValue()
					}, 100)
				})
			}
		},
		setValue: function (year) {
			this.$emit('input', year)
		},
		scrollToCurrentValue: function () {
			let actives = document.getElementsByClassName('active')
			if (actives) {
				let elementToScrollTo = null
				let elementParent = null
				for (let i= 0; i < actives.length; i++) {
					if (actives[i].textContent === this.value.toString()) {
						elementToScrollTo = actives[i]
						elementParent = elementToScrollTo.parentNode
					}
				}
				let topPos = elementToScrollTo.offsetTop
				elementParent.scrollTop = topPos
			}
		}
	}
}
</script>
